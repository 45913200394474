<template>
  <div>
    <h4 class="media align-items-center font-weight-bold py-3 mb-4">
      <div class="media-body ml-3">
        Bienvenido, {{ $session.get("Nombres") }}!
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">{{ fechaAhora }}</small>
        </div>
      </div>
    </h4>
    <hr class="container-m-nx mt-0 mb-4" />
    <div class="card card-custom mb-3">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            <select v-model="regionSeleccionada">
              <option :value="null">Todas las regiones</option>
              <option v-for="item in regiones" :value="item" :key="item.regionId">{{ item.nombre }}</option>
            </select>

            <span v-if="filtro.regionId || filtro.subRegionId">
              <i class="fa fa-chevron-right mx-2"></i>

              <select v-model="subRegionSeleccionada">
                <option :value="null">Todas las subregiones</option>
                <option v-for="item in subRegionesPorRegionFiltrada" :value="item" :key="item.subRegionId">{{ item.nombre }}</option>
              </select>
            </span>
            <b-btn v-if="filtro.regionId || filtro.subRegionId" variant="default" @click="borrarFiltroRegion" class="ml-2"><i class="fa fa-times"></i></b-btn>
          </h3>
        </div>
        <div class="card-toolbar">
          <span class="text-warning mr-2" v-if="actualizacionAutomatica.habilitado"> {{ actualizacionAutomatica.intervaloPendiente }} s </span>
          <a href="#" class="btn btn-sm btn-light-warning font-weight-bold" @click.prevent="actualizacionAutomatica.habilitado = !actualizacionAutomatica.habilitado">
            <i class="fa p-0" :class="[actualizacionAutomatica.habilitado ? 'fa-pause' : 'fa-play']"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="d-flex col-xl-6 align-items-stretch">
        <b-card no-body class="w-100 mb-4 ">
          <b-card-body class="p-0">
            <div class="map-wrapper" style="width:100%">
              <highcharts :constructorType="'mapChart'" :options="chartOptionsPeru" ref="chartPeru"></highcharts>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="d-flex col-xl-6 ">
        <!-- 
          
        <div class="row">
          <div class="card" style="">
            <div class="card-body">
              <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block"> <span class="font-weight-bolder">25</span> % </span>
              <span class="font-weight-bold text-muted font-size-sm">Avance Turno 1</span>
            </div>
          </div>
        </div>

         -->

        <div class="d-flex w-100 mb-4">
          <div class="row no-gutters row-bordered h-100">
            <div class="card col-xl-12 col-lg-12" style="">
              <div @click.prevent="turnoMapaSeleccionado = null" :class="[turnoMapaSeleccionado == null ? 'bg-light' : '']" class="card-body p-2  cursor-pointer" style="flex-direction: column;display: flex;align-items: center;justify-content: center;">
                <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">
                  <span class="font-weight-bolder">{{ datosAvanceGeneral.all }}</span> %
                </span>
                <span class="font-weight-bold text-muted font-size-sm">Avance General</span>
              </div>
            </div>
            <div class="card col-xl-6 col-lg-6" style="">
              <div @click.prevent="turnoMapaSeleccionado = 1" :class="[turnoMapaSeleccionado == 1 ? 'bg-light' : '']" class="card-body p-2  cursor-pointer" style="flex-direction: column;display: flex;align-items: center;justify-content: center;">
                <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">
                  <span class="font-weight-bolder">{{ datosAvanceGeneral.t1 }}</span> %
                </span>
                <span class="font-weight-bold text-muted font-size-sm">Avance Turno 1</span>
              </div>
            </div>
            <div class="card col-xl-6 col-lg-6" style="">
              <div @click.prevent="turnoMapaSeleccionado = 2" :class="[turnoMapaSeleccionado == 2 ? 'bg-light' : '']" class="card-body p-2  cursor-pointer" style="flex-direction: column;display: flex;align-items: center;justify-content: center;">
                <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">
                  <span class="font-weight-bolder">{{ datosAvanceGeneral.t2 }}</span> %
                </span>
                <span class="font-weight-bold text-muted font-size-sm">Avance Turno 2</span>
              </div>
            </div>
            <div class="card col-xl-6 col-lg-6" style="">
              <div @click.prevent="turnoMapaSeleccionado = 3" :class="[turnoMapaSeleccionado == 3 ? 'bg-light' : '']" class="card-body p-2  cursor-pointer" style="flex-direction: column;display: flex;align-items: center;justify-content: center;">
                <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">
                  <span class="font-weight-bolder">{{ datosAvanceGeneral.t3 }}</span> %
                </span>
                <span class="font-weight-bold text-muted font-size-sm">Avance Turno 3</span>
              </div>
            </div>
            <div class="card col-xl-6 col-lg-6" style="">
              <div @click.prevent="turnoMapaSeleccionado = 4" :class="[turnoMapaSeleccionado == 4 ? 'bg-light' : '']" class="card-body p-2  cursor-pointer" style="flex-direction: column;display: flex;align-items: center;justify-content: center;">
                <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 d-block">
                  <span class="font-weight-bolder">{{ datosAvanceGeneral.t4 }}</span> %
                </span>
                <span class="font-weight-bold text-muted font-size-sm">Avance Turno 4</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div v-for="(sectionReporte, indexSectionReporte) in estructuraReportesGenericosTurnos" :key="indexSectionReporte">
      <h1 class="text-center mb-5 mt-5">{{ sectionReporte.titulo }}</h1>

      <div v-for="(sectionTurno, indexSectionTurno) in sectionReporte.turnos" :key="indexSectionTurno">
        <div class="card card-custom mb-3">
          <div class="card-header">
            <div class="card-title" @click.prevent="sectionTurno.expandirContenido = !sectionTurno.expandirContenido">
              <h3 class="card-label cursor-pointer"><span class="label label-xl label-dot mr-2" :class="[sectionTurno.dotStyle]"></span> {{ sectionTurno.titulo }} <i class="mr-2"></i></h3>
            </div>
            <div class="card-toolbar">
              <a href="#" class="btn btn-sm btn-light-warning font-weight-bold" @click.prevent="sectionTurno.expandirContenido = !sectionTurno.expandirContenido"><i class="fa p-0" :class="[sectionTurno.expandirContenido ? 'fa-chevron-up' : 'fa-chevron-down']"></i></a>
            </div>
          </div>
        </div>

        <div class="row" v-if="sectionTurno.expandirContenido">
          <div v-for="item in sectionTurno.reportes" :key="item.reporteDataId" :class="item.configuracionVisualizacion.configStyle.colClass" class="col-sm-6 col-xl-4">
            <data-chart-card
              v-if="item.configuracionVisualizacion.tipoChart == 'DATA-CHART'"
              class=""
              :title="item.configuracionVisualizacion.configDataChart.title"
              :colores="item.configuracionVisualizacion.configStyle.colores"
              :height="item.configuracionVisualizacion.configDataChart.height"
              :defaultView="item.configuracionVisualizacion.configDataChart.defaultView"
              :section="item.configuracionVisualizacion.configDataChart.section"
              :code="item.configuracionVisualizacion.configDataChart.code"
              :filaTieneTitulo="item.configuracionVisualizacion.configDataChart.filaTieneTitulo"
              :allowTableExceedHeight="item.configuracionVisualizacion.configDataChart.allowTableExceedHeight"
              :pivotTable="item.configuracionVisualizacion.configDataChart.pivotTable"
              :pivotReportData="item.configuracionVisualizacion.configDataChart.pivotReportData"
              :chartType="item.configuracionVisualizacion.configDataChart.chartType"
              :procesoElectoralId="filtro.procesoElectoralId"
              :localVotacionId="filtro.localVotacionId"
              :regionId="filtro.regionId"
              :subRegionId="filtro.subRegionId"
              :key="actualizacionAutomatica.forceUpdateKey"
            ></data-chart-card>
            <single-data-card
              v-if="item.configuracionVisualizacion.tipoChart == 'SINGLE'"
              class=""
              :title="item.configuracionVisualizacion.configSingle.title"
              :colores="item.configuracionVisualizacion.configStyle.colores"
              :suffix="item.configuracionVisualizacion.configSingle.suffix"
              :iconClass="item.configuracionVisualizacion.configSingle.iconClass"
              :section="item.configuracionVisualizacion.configSingle.section"
              :code="item.configuracionVisualizacion.configSingle.code"
              :filaTieneTitulo="item.configuracionVisualizacion.configSingle.filaTieneTitulo"
              :column="item.configuracionVisualizacion.configSingle.column"
              :columnIndex="item.configuracionVisualizacion.configSingle.columnIndex"
              :rowIndex="item.configuracionVisualizacion.configSingle.rowIndex"
              :pivotReportData="item.configuracionVisualizacion.configSingle.pivotReportData"
              :procesoElectoralId="filtro.procesoElectoralId"
              :localVotacionId="filtro.localVotacionId"
              :regionId="filtro.regionId"
              :subRegionId="filtro.subRegionId"
              :key="actualizacionAutomatica.forceUpdateKey"
            ></single-data-card>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
.map-tooltip {
  color: white;
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.map-tooltip-title {
  font-size: 17px;
  font-weight: bold;
  width: 150px;
  white-space: normal;
  display: block;
}
.map-tooltip-detail {
  align-items: center;
  display: flex;
  justify-content: center;
}
.map-tooltip-detail i {
  font-size: 30px;
}
.map-tooltip-value {
  font-size: 16px;
  font-weight: bold;
}
.map-tooltip-text-inscritos {
}
</style>

<script>
import Vue from "vue";
import moment from "moment";

import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsMapModule from "highcharts/modules/map";
import peruMap from "@highcharts/map-collection/countries/pe/pe-all.geo.json";

import dataChartCard from "@/components/report/data-chart-card";
import singleDataCard from "@/components/report/single-data-card";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

HighchartsMapModule(Highcharts);

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard",
  },
  components: {
    highcharts: Chart,
    dataChartCard,
    singleDataCard,
  },
  data: () => ({
    moment: moment,
    fechaAhora: null,
    fechaUltimaActualizacion: null,
    temporizadorFechaActual: null,
    actualizacionAutomatica: {
      temporizador: null,
      intervaloSegundos: 300,
      intervaloPendiente: 300,
      habilitado: true,
      forceUpdateKey: 1,
    },

    regiones: [],
    subRegiones: [],

    regionSeleccionada: null,
    subRegionSeleccionada: null,

    filtro: {
      procesoElectoralId: 0,
      localVotacionId: null,
      regionId: null,
      regionNombre: null,
      subRegionId: null,
      subRegionNombre: null,
    },
    datosMapaPeru: [],
    datosAvanceGeneral: {},
    turnoMapaSeleccionado: null,
    estructuraReportesGenericosTurnos: [],
    reportesGenericos: [
      /*
    {
      reporteDataId: null,
      seccion: null,
      codigo: null,
      titulo: null,
      subTitulo: null,
      descripcion: null,
      sentenciaSelect: null,
      sentenciaOrderBy: null,
      sentenciaJsonFormat: null,
      sentenciaParametros: "",
      configuracionVisualizacion: null,

      esEliminado: false,
    }

      configuracionVisualizacion: {
        tipoChart: "DATA-CHART",
          configDataChart: {
            title: "",
            height: null,
            defaultView: null, //["table", "chart"],
            section: "",
            code: "",
            filaTieneTitulo: true,
            allowTableExceedHeight: true,
            pivotTable: false,
            pivotReportData: false,
            chartType: null, //["pie", "chart", "bar"],
            componentForceUpdateKey: 0,
          },
          configSingle: {
            title: "",
            prefix: null,
            suffix: null,
            iconClass: "fa fa-chart-area",
            section: null,
            code: null,
            filaTieneTitulo: false,
            column: null,
            columnIndex: null,
            rowIndex: null,
            pivotReportData: false,
            componentForceUpdateKey: 0,
            rawDataForceUpdateKey: -1,
          },
          configStyle: {
            colClass: "col-sm-6 col-xl-4 col-xxl-3",
            orden: 1,
          },
      }*/
    ],
  }),
  computed: {
    reportesGenericosSeccionTurno1: function() {
      if (!this.reportesGenericos) return [];

      return this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-T1");
    },

    subRegionesPorRegionFiltrada: function() {
      if (!this.filtro.regionId) return [];

      return this.subRegiones.filter((x) => x.regionId == this.filtro.regionId);
    },

    serieDatosMapaPeru: function() {
      var serie = [];
      if (this.datosMapaPeru) {
        for (let i = 0; i < this.datosMapaPeru.length; i++) {
          var data = this.datosMapaPeru[i];
          var porcentaje = data.all;

          switch (this.turnoMapaSeleccionado) {
            case 1:
              porcentaje = data.t1;
              break;
            case 2:
              porcentaje = data.t2;
              break;
            case 3:
              porcentaje = data.t3;
              break;
            case 4:
              porcentaje = data.t4;
              break;
            // case 5:
            //   porcentaje = data.t5;
            //   break;
          }

          var codigos = data.codigoMapa.split("|");

          var color = "#d4d6dd";

          if (porcentaje > 0) {
            color = "#f64e60"; //rojo
          }

          if (porcentaje > 75) {
            color = "#ffa800"; //amarillo
          }

          if (porcentaje > 95) {
            color = "#1bc5bd"; //verde
          }

          for (let c = 0; c < codigos.length; c++) {
            serie.push([codigos[c], porcentaje, data.region, data.regionId, color]);
          }
        }
      }
      return serie;
    },
    chartOptionsPeru: function() {
      var vm = this;
      var options = {
        chart: {
          map: peruMap,
          backgroundColor: "rgba(0,0,0,0)",
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          map: {
            nullColor: "#808285",
          },
          series: {
            point: {
              events: {
                click: function() {
                  console.log(this.regionId);
                  // vm.regionSeleccionada = vm.regiones.find((x) => (x.regionId = this.regionId));
                },
              },
            },
          },
        },
        /*tooltip: {
          pointFormatter: function() {
            debugger;
            return this.nombre + ": " + this.value;
          },
        },*/
        tooltip: {
          followPointer: false,
          // shape: "circle",
          //useHTML: true,
          // borderColor: "#ffffff",
          //  backgroundColor: "#14acef",
          // borderWidth: 2,
          // headerFormat: '<div class="map-tooltip">',
          pointFormat: "{point.nombre}: {point.value}%",
          // footerFormat: "</div>",
        },
        series: [
          {
            keys: ["hc-key", "value", "nombre", "regionId", "color"],
            name: "Avance",
            states: {
              hover: {
                color: null,
                brightness: -0.1,
              },
            },
            dataLabels: {
              enabled: false,
              format: "{point.name} %",
            },
            allAreas: true,
            data: [],
          },
        ],
      };

      options.series[0].data = this.serieDatosMapaPeru;

      return options;
    },
  },
  methods: {
    generateEstructuraReportesGenericosTurnos: function() {
      if (!this.reportesGenericos) {
        this.estructuraReportesGenericosTurnos = [];
        return;
      }

      this.estructuraReportesGenericosTurnos = [
        {
          titulo: "",
          expandirContenido: true,
          turnos: [
            {
              titulo: "Porcentaje de avance de completado de fichas, por región",
              dotStyle: "label-light",
              expandirContenido: true,
              reportes: this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-AVR"),
            },
          ],
        },
        {
          titulo: "REPORTE 1",
          expandirContenido: true,
          turnos: [
            {
              titulo: "Turno 1",
              dotStyle: "label-primary",
              expandirContenido: true,
              reportes: this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-T1"),
            },
            {
              titulo: "Turno 2",
              dotStyle: "label-danger",
              expandirContenido: true,
              reportes: this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-T2"),
            },
          ],
        },
        {
          titulo: "REPORTE 2",
          expandirContenido: true,
          turnos: [
            {
              titulo: "Turno 3",
              dotStyle: "label-success",
              expandirContenido: true,
              reportes: this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-T3"),
            },
          ],
        },
        {
          titulo: "REPORTE 3",
          expandirContenido: true,
          turnos: [
            {
              titulo: "Turno 4",
              dotStyle: "label-info",
              expandirContenido: true,
              reportes: this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-T4"),
            },
            // {
            //   titulo: "Turno 5",
            //   dotStyle: "label-warning",
            //   expandirContenido: true,
            //   reportes: this.reportesGenericos.filter((x) => x.seccion == "DASHBOARD-T5"),
            // },
          ],
        },
      ];
    },

    borrarFiltroRegion: function() {
      this.filtro.regionId = null;
      this.filtro.regionNombre = null;
      this.filtro.subRegionId = null;
      this.filtro.subRegionNombre = null;
      this.regionSeleccionada = null;
      this.subRegionSeleccionada = null;
    },

    setRegion: function(nombre, id) {
      this.filtro.regionId = id;
      this.filtro.regionNombre = nombre;
      this.filtro.subRegionId = null;
      this.filtro.subRegionNombre = null;
      this.subRegionSeleccionada = null;
    },
    setSubRegion: function(nombre, id) {
      //this.filtro.regionId = null;
      //this.filtro.regionNombre = null;
      this.filtro.subRegionId = id;
      this.filtro.subRegionNombre = nombre;
    },
    fetchEstadisticasMapa: async function() {
      var params = {
        procesoElectoralId: this.filtro.procesoElectoralId,
      };

      var model = {
        seccion: "DASHBOARD-GEN",
        codigo: "AVG_AvanceMapa",
        filaTieneTitulo: false,
        parametros: { ...params },
      };
      await this.$http.post(this.apiRoute("ReportData", "GetJson"), model).then(function(response) {
        if (!response.body.error) {
          this.datosMapaPeru = response.body.data;
        }
      });
    },

    fetchEstadisticasTurnos: async function() {
      var params = {
        procesoElectoralId: this.filtro.procesoElectoralId,
      };

      var model = {
        seccion: "DASHBOARD-GEN",
        codigo: "AVG_Avance",
        filaTieneTitulo: false,
        parametros: { ...params },
      };
      await this.$http.post(this.apiRoute("ReportData", "GetJson"), model).then(function(response) {
        if (!response.body.error && response.body.data.length > 0) {
          this.datosAvanceGeneral = response.body.data[0];
        }
      });
    },

    fetchReportesGenericos: function() {
      this.showLoading();
      var model = {};
      this.$http.post(this.apiRoute("ReportData", "ListReportes"), model).then(function(response) {
        if (!this.procesarRespuestaErrorDefecto(response)) {
          var reportesGenericosTemp = [];

          for (var i = 0; i < response.body.data.items.length; i++) {
            var reporteGenericoTemp = response.body.data.items[i];
            if (!reporteGenericoTemp.configuracionVisualizacion) continue;

            reporteGenericoTemp.configuracionVisualizacion = JSON.parse(reporteGenericoTemp.configuracionVisualizacion || "{}");
            reportesGenericosTemp.push(reporteGenericoTemp);
          }

          this.reportesGenericos = reportesGenericosTemp;
          this.generateEstructuraReportesGenericosTurnos();
          this.showLoading(false);
        }
      });
    },

    fetchRegiones: function() {
      var modelRegion = {};

      this.$http.post(this.apiRoute("Region", "ListRegiones"), modelRegion).then(function(response) {
        this.regiones = response.body.data.items;
      });
    },

    fetchSubRegiones: function() {
      var modelSubRegion = {};

      this.$http.post(this.apiRoute("Region", "ListSubRegiones"), modelSubRegion).then(function(response) {
        this.subRegiones = response.body.data.items;
      });
    },
    onIncidenteRegistrado: function(params) {
      console.log("Incidente Registrado");
      console.log(params);
      //tipo, localNombre, localDireccion, localSubRegion, localRegion

      var tipoString = "";
      switch (params.tipo) {
        case "ACTO":
          tipoString = "Acto de Violencia";
          break;
        case "OTROS":
          tipoString = "Incidente (otros)";
          break;
      }

      var detail = 'Registrado en el local "' + params.localNombre + '" ubicado en ' + params.localRegion + " / " + params.localSubRegion + ".";

      this.$bvToast.toast(detail, {
        title: tipoString + " en " + params.localSubRegion,
        autoHideDelay: 5 * 60 * 1000,
        variant: "danger",
        appendToast: false,
        solid: true,
        noAutoHide: true,
      });
    },
  },
  watch: {
    regionSeleccionada: function(newValue, oldValue) {
      if (newValue) {
        this.setRegion(newValue.nombre, newValue.regionId);
      } else {
        this.setRegion(null, null);
      }
    },
    subRegionSeleccionada: function(newValue, oldValue) {
      if (newValue) {
        this.setSubRegion(newValue.nombre, newValue.subRegionId);
      } else {
        this.setSubRegion(null, null);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Seguimiento" }]);
    this.moment.locale("es");
    this.fechaAhora = moment(new Date()).format("D MMMM YYYY, h:mm:ss a");
    this.filtro.procesoElectoralId = this.$session.get("ProcesoElectoralId");
    this.fetchEstadisticasMapa();
    this.fetchEstadisticasTurnos();
    this.fetchReportesGenericos();
    this.fetchRegiones();
    this.fetchSubRegiones();

    var vm = this;
    this.temporizadorFechaActual = setInterval(function() {
      vm.fechaAhora = moment(new Date()).format("D MMMM YYYY, h:mm:ss a");
    }, 1000);

    this.actualizacionAutomatica.temporizador = setInterval(function() {
      if (!vm.actualizacionAutomatica.habilitado) return;

      vm.actualizacionAutomatica.intervaloPendiente -= 1;
      if (vm.actualizacionAutomatica.intervaloPendiente == 0) {
        vm.actualizacionAutomatica.forceUpdateKey += 1;
        vm.fetchEstadisticasMapa();
        vm.fetchEstadisticasTurnos();
      }
      if (vm.actualizacionAutomatica.intervaloPendiente < 0) {
        vm.actualizacionAutomatica.intervaloPendiente = vm.actualizacionAutomatica.intervaloSegundos;
      }
    }, 1000);

    setTimeout(function() {
      vm.$notifyHub.subscribeFichaGroup("ADM-DASHBOARD");
      vm.$notifyHub.$on("incidente-registrado", vm.onIncidenteRegistrado);
    }, 1000);
  },
  beforeDestroy() {
    // Notify the server we stopped watching the question
    this.$notifyHub.unsubscribeFichaGroup("ADM-DASHBOARD");
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$notifyHub.$off("incidente-registrado", this.onIncidenteRegistrado);
  },
  destoyed: function() {
    clearInterval(this.temporizadorFechaActual);
    clearInterval(this.actualizacionAutomatica.temporizador);
  },
};
</script>
